<app-loading-page *ngIf="!loaded"></app-loading-page>
<mat-card *ngIf="loaded">
  <mat-card-content>
    <table [dataSource]="dataSource" aria-label="Elements" class="full-width-table" mat-table>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Name</th>
        <td *matCellDef="let row" mat-cell>{{row.displayName}}</td>
      </ng-container>

      <ng-container matColumnDef="managed">
        <th *matHeaderCellDef mat-header-cell>Verwaltet</th>
        <td *matCellDef="let row" mat-cell><span *ngIf="row.managed">Ja</span><span *ngIf="!row.managed">Nein</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="members">
        <th *matHeaderCellDef mat-header-cell>Mitglieder</th>
        <td *matCellDef="let row" mat-cell>

        </td>
      </ng-container>

      <ng-container matColumnDef="info" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <a [routerLink]="['./group', element.id]">
            <mat-icon>more_vert</mat-icon>
          </a>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource.numItem"
                   [pageIndex]="0"
                   [pageSizeOptions]="[25, 50, 100, 250]"
                   [pageSize]="50">
    </mat-paginator>
  </mat-card-content>
</mat-card>
