import {Component} from '@angular/core';
import {NewsService} from '../../api';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  constructor(private newsService: NewsService,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
  }
}
