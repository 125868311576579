<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card class="feed-form">
    <mat-card-header>
      <mat-card-title>Feed</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="title" matInput placeholder="Title">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select formControlName="type" placeholder="Type">
              <mat-option *ngFor="let feedType of feedTypes" [value]="feedType.key">
                {{ feedType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="releasedate" matInput placeholder="Release date" type="date">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="releasetime" matInput placeholder="Release time" type="time">
          </mat-form-field>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="form.get('type').value == 'news'">
    <mat-card-content formGroupName="payloadNews">
      <div class="quill-editor">
        <quill-editor formControlName="text" [modules]=editorModules (onEditorCreated)=onEditorReady($event)></quill-editor>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="form.get('type').value == 'newsletter'">
    <mat-card-content formGroupName="payloadNewsletter">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="url" matInput placeholder="Url">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="form.get('type').value == 'event'">
    <mat-card-content formGroupName="payloadEvent">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="url" matInput placeholder="Url">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="multiday">Mehrtägig</mat-checkbox>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="startdate" matInput placeholder="Start date" type="date">
          </mat-form-field>
        </div>
        <div *ngIf="form.get('payloadEvent').get('multiday').value" class="col">
          <mat-form-field class="full-width">
            <input formControlName="enddate" matInput placeholder="Enddate date" type="date">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!form.get('payloadEvent').get('multiday').value" class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="starttime" matInput placeholder="Start time" type="time">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="endtime" matInput placeholder="End time" type="time">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title-image-actions">
            <mat-form-field class="full-width">
              <input disabled matInput placeholder="Titelbild" type="text" value="{{selectedImage?.file_name}}"/>
            </mat-form-field>
            <button (click)="openImageDialog()" *ngIf=!selectedImage color="secondary" mat-button type="button">
              Hinzufügen
            </button>
            <button (click)="openImageDialog()" *ngIf=selectedImage color="secondary" mat-button type="button">Ändern
            </button>
            <button (click)="removeTitleImage()" *ngIf=selectedImage color="warn" mat-button type="button">Entfernen
            </button>
          </div>
          <div *ngIf=selectedImage>Vorschau</div>
          <img *ngIf="selectedImage" src="{{selectedImage.preview_url}}" width="200" />
        </div>
      </div>
      <div class="quill-editor">
        <quill-editor formControlName="description" [modules]=editorModules (onEditorCreated)=onEditorReady($event)></quill-editor>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="form.get('type').value == 'survey'">
    <mat-card-content formGroupName="payloadSurvey">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="url" matInput placeholder="Url">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="feed-form">
    <mat-card-content>
      <mat-form-field appearance="fill">
        <mat-label>Leseberechtigungen</mat-label>
        <mat-select formControlName="readerGroups" multiple>
          <mat-option *ngFor="let group of usergroups" [value]="group.name">{{group.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card-content>
  </mat-card>
  <mat-card class="feed-form">
    <mat-card-actions>
      <button color="primary" mat-raised-button type="submit">Speichern</button>
    </mat-card-actions>
  </mat-card>
</form>
