import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Feed, ForumService, NewsService, OnkologischePharmazie, OnkoPharmService} from '../../api';
import {ActivatedRoute} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {LoadingComponent} from '../loading/loading.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ListComponent} from '../list/list.component';

@Component({
  selector: 'app-onko-pharm-list',
  templateUrl: './onko-pharm-list.component.html',
  styleUrls: ['./onko-pharm-list.component.css']
})
export class OnkoPharmListComponent extends ListComponent<OnkologischePharmazie> implements AfterViewInit {
  displayedColumns = ['name', 'releasedate', 'info'];

  constructor(private service: OnkoPharmService,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  apiRequest = (offset, limit) => this.service.onkoListGet(offset, limit, null, 'response');
}
