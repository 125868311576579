import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Feed, IamService} from '../../api';
import {ListComponent} from '../list/list.component';

@Component({
  selector: 'app-settings-user-list',
  templateUrl: './settings-user-list.component.html',
  styleUrls: ['./settings-user-list.component.css']
})
export class SettingsUserListComponent extends ListComponent<Feed> {
  displayedColumns = ['name', 'email', 'dgop', 'info'];

  constructor(private iamService: IamService,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  apiRequest = (offset, limit) => this.iamService.userListGet(offset, limit, 'response');
}
