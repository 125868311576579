import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditComponent} from '../edit/edit.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {IamService, Role, User} from '../../api';

@Component({
  selector: 'app-settings-group-edit',
  templateUrl: './settings-group-edit.component.html',
  styleUrls: ['./settings-group-edit.component.css']
})
export class SettingsGroupEditComponent extends EditComponent {
  roleList: Role[] = [];

  form = this.fb.group({
    id: [0, Validators.required],
    displayName: ['', Validators.required],
    name: ['', Validators.required],
    managed: [false, Validators.required],
    roles: [[]],
  });

  members: User[] = [];

  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private iamService: IamService,
              snackBar: MatSnackBar) {
    super(fb, route, router, snackBar);
  }

  loadData(): Observable<any> {
    this.form.disable();
    return this.route.paramMap.pipe(mergeMap(
      params => {
        const groupId = Number(params.get('groupId'));
        const sources = [this.iamService.roleListGet().pipe(map(roles => {
          this.roleList = roles.sort((l, r) => l.displayName.localeCompare(r.displayName));
        }))];
        if (groupId !== 0) {
          sources.push(this.iamService.groupIdGetGet(groupId).pipe(map(group => {
            this.form.get('id').setValue(group.id);
            this.form.get('name').setValue(group.name);
            this.form.get('displayName').setValue(group.displayName);
            this.form.get('managed').setValue(group.managed);
          })));
          sources.push(this.iamService.groupIdMembersGet(groupId).pipe(map(members => {
              this.members = members;
            }
          )));
        }
        return forkJoin(sources);
      }
    ));
  }

  storeData(): Observable<any> {
    return of('');
  }
}
