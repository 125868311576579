<mat-card-header><mat-card-title>Anmeldung</mat-card-title></mat-card-header>
<mat-card-content *ngIf="isActive == null">
  <mat-spinner class="data-loading"></mat-spinner>
</mat-card-content>
<mat-card-content *ngIf="isActive == false">
  <p>
    Die Anmeldung ist zurzeit nicht aktiviert.
  </p>
</mat-card-content>
<mat-card-content *ngIf="!finalUser && isActive">
  <p>
    Achtung! Dies ist nur eine Anmeldung bei der App. Hierdurch sind Sie nicht automatich DGOP Mitglied.
  </p>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="email" matInput placeholder="Email">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="password" matInput placeholder="Password" type="password">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="repeatPassword" matInput placeholder="Password wiederholen" type="password">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="title" matInput placeholder="Titel">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="firstname" matInput placeholder="Vorname">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="lastname" matInput placeholder="Nachname">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <input formControlName="captchaCode" matInput placeholder="Captcha Code">
        </mat-form-field>
        <img [src]="captchaImage" alt=""/>
        <button (click)="renewCaptcha()" color="accent" mat-button type="button">Neuer Captcha</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button color="primary" mat-raised-button type="submit">Anmelden</button>
      </div>
    </div>
  </form>
</mat-card-content>
<mat-card-content *ngIf="finalUser">
  <p>
    Herzlich Willkommen in der DGOP App,<br><br>

    bitte bestätigen Sie ihre Email Adresse. Dann kann es in der App gleich losgehen.<br><br>

    Mit freundliche Grüßen<br>
    Ihr DGOP App Team
  </p>
</mat-card-content>
