import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {MatPaginator} from '@angular/material/paginator';
import {tap} from 'rxjs/operators';
import {CollectionViewer} from '@angular/cdk/collections';

export class ApiDataSource<T> implements DataSource<T> {
  public numItem = 0;
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor() {
  }

  private _loadData: (offset: number, limit: number) => Observable<HttpResponse<T[]>>;
  set loadData(loadData: (offset: number, limit: number) => Observable<HttpResponse<T[]>> | null) {
    this._loadData = loadData;
  }

  private _paginator;
  get paginator(): MatPaginator | null {
    return this._paginator;
  }

  set paginator(paginator: MatPaginator | null) {
    this._paginator?.page.unsubscribe();
    this._paginator = paginator;
    this._paginator?.page.pipe(
      tap(() => this.fetchData())
    ).subscribe();
  }

  fetchData(): void {
    let offset = 0;
    let limit = 10;
    this.loadingSubject.next(true);

    if (this._paginator != null) {
      offset = this.paginator.pageIndex * this.paginator.pageSize;
      limit = this.paginator.pageSize;
    }
    this._loadData(offset, limit).subscribe(resp => {
        if (resp.headers.has('X-List-Length')) {
          this.numItem = parseInt(resp.headers.get('X-List-Length'));
        }
        this.dataSubject.next(resp.body);

      }, (err) => {
      },  // TODO handle errors(print in snackbox)
      () => {
        this.loadingSubject.next(false);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
