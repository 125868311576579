<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card class="feed-form">
    <mat-card-header>
      <mat-card-title>Topic</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="name" matInput placeholder="Name">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="feed-form">
    <mat-card-actions>
      <button color="primary" mat-raised-button type="submit">Speichern</button>
    </mat-card-actions>
  </mat-card>
</form>
