/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface Forum { 
    id?: number;
    name?: string;
    description?: string;
    created_at?: Date;
    created_by?: User;
    updated_at?: Date;
    updated_by?: User;
    num_topics?: number;
    num_messages?: number;
    last_message_at?: Date;
}

