import {Component, Inject} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditComponent} from '../edit/edit.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Feed, Forum, ForumService, IamPolicy, IamService, Role, Topic, User} from '../../api';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-forum-edit',
  templateUrl: './forum-topic-dialog.component.html',
  styleUrls: ['./forum-topic-dialog.component.css']
})
export class ForumTopicDialogComponent extends EditComponent {
  topic: Topic;
  form = this.fb.group({
    id: [0, Validators.required],
    name: ['', Validators.required],
  });

  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private forumService: ForumService,
              snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: Topic) {
    super(fb, route, router, snackBar);
    this.topic = data;
    this.form.get('id').setValue(this.topic.id);
    this.form.get('name').setValue(this.topic.name);
  }

  loadData(): Observable<any> {
    if (this.topic.id !== 0) {
      return this.forumService.forumForumIdTopicTopicIdGetGet(this.topic.forum.id, this.topic.id)
        .pipe(map(topic => {
          this.form.get('id').setValue(topic.id);
          this.form.get('name').setValue(topic.name);
        }));
    }
    return of('');
  }

  storeData(): Observable<any> {
    this.topic.name = this.form.get('name').value;

    const storeTopic = this.topic.id === 0 ?
      this.forumService.forumForumIdTopicCreatePost(this.topic.forum.id, this.topic)
      : this.forumService.forumForumIdTopicTopicIdUpdatePost(this.topic.forum.id, this.topic.id, this.topic);

    return storeTopic.pipe(map(ref => {
      this.form.get('id').setValue(ref.id);
      this.topic.id = ref.id;
    }));
  }

  finalizeSubmit(): void {
    return;
  }
}
