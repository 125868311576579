import {Component, OnInit} from '@angular/core';
import {IamService} from '../api';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {of} from 'rxjs';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
  imports: [MatCardModule],
  standalone: true,
})
export class VerifyEmailComponent implements OnInit {
  private static ERROR_MESSAGE = 'Der E-Mail konnte nicht verifiziert werden, versuchen Sie es später nochmal.';
  private static SUCCESSFUL_MESSAGE = 'Danke das Sie ihre E-Mail bestätigt haben, Sie können sich nun ganz normal in der App einloggen.';
  public message: string;

  constructor(private route: ActivatedRoute, private iamService: IamService) {
    iamService.userVerifyPost({verify_code: ''});
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(pm => {
      if (pm.has('token')) {
        const token = pm.get('token');
        this.iamService.userVerifyPost({verify_code: token}).subscribe(
          res => {
            this.message = VerifyEmailComponent.SUCCESSFUL_MESSAGE;
          },
          err => {
            this.message = VerifyEmailComponent.ERROR_MESSAGE;
          },
        );
      } else {
        this.message = VerifyEmailComponent.ERROR_MESSAGE;
      }
      return of('');
    });
  }

}
