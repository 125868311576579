import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: BehaviorSubject<SocialUser> = new BehaviorSubject<SocialUser>(null);
  private idTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  authState: Observable<SocialUser>;
  idToken: Observable<string>;

  constructor(public auth: SocialAuthService) {
    this.authState = this.user.asObservable();
    this.idToken = this.idTokenSubject.asObservable();
    this.auth.authState.subscribe((user) => {
      this.idTokenSubject.next(user?.idToken);
      this.user.next(user);
    });
  }

  refreshAuthToken(providerId: string): Promise<void> {
    return this.auth.refreshAuthToken(providerId);
  }

  signOut(revoke?: boolean): Promise<void> {
    return this.auth.signOut(revoke);
  }
}
