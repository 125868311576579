<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card class="feed-form">
    <mat-card-header>
      <mat-card-title>Forum</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="name" matInput placeholder="Name">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="description" matInput placeholder="Beschreibung">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Topics</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let topic of topics; let i = index" >
          <mat-expansion-panel-header>
            <mat-panel-title>{{ topic.name }}</mat-panel-title>
          </mat-expansion-panel-header>
          <button mat-raised-button (click)="onClickEditTopic(topic)">Edit</button>
        </mat-expansion-panel>
      </mat-accordion>
      <br>
      <button mat-raised-button (click)="onClickAddTopic()">Add</button>
    </mat-card-content>
  </mat-card>
  <mat-card class="feed-form">
    <mat-card-actions>
      <button color="primary" mat-raised-button type="submit">Speichern</button>
    </mat-card-actions>
  </mat-card>
</form>
