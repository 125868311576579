import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditComponent} from '../edit/edit.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {ConeventUser, IamService, User, UserDetail, UserGroup} from '../../api';

@Component({
  selector: 'app-settings-user-edit',
  templateUrl: './settings-user-edit.component.html',
  styleUrls: ['./settings-user-edit.component.css']
})
export class SettingsUserEditComponent extends EditComponent {
  groupList: UserGroup[] = [];
  userDetail: UserDetail = null;
  possibleConeventUsers: ConeventUser[] = [];

  form = this.fb.group({
    id: [0, Validators.required],
    title: [''],
    email: ['', [Validators.required, Validators.email]],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    city: [''],
    country: [''],
    coneventDBId: [null],
    groups: [[] as Array<string>],
  });

  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private iamService: IamService,
              snackBar: MatSnackBar) {
    super(fb, route, router, snackBar);
  }

  loadData(): Observable<any> {
    return this.route.paramMap.pipe(mergeMap(
      params => {
        const userId = Number(params.get('userId'));
        const sources = [this.iamService.groupListGet().pipe(map(groups => {
          this.groupList = groups.sort((l, r) => l.displayName.localeCompare(r.displayName));
        }))];
        if (userId !== 0) {
          this.form.get('email').disable();
          sources.push(this.iamService.userIdDetailGet(userId).pipe(map(userDetail => {
            const user = userDetail.user;
            this.form.get('email').setValue(user.email);
            this.form.get('id').setValue(user.id);
            this.form.get('title').setValue(user.title);
            this.form.get('firstname').setValue(user.firstname);
            this.form.get('lastname').setValue(user.lastname);
            this.form.get('city').setValue(user.city);
            this.form.get('country').setValue(user.country);
            this.form.get('groups').setValue(user.groups.map(g => '' + g.id));
            this.form.get('coneventDBId').setValue(userDetail.conevent_db_id);
            this.userDetail = userDetail;
          })));
          sources.push(this.iamService.userIdSearchconeventGet(userId).pipe(map(coneventUsers => {
            this.possibleConeventUsers = coneventUsers;
          })));
        }
        return forkJoin(sources);
      }
    ));
  }

  storeData(): Observable<any> {
    const userId = this.form.get('id').value;
    const groups: UserGroup[] = [];
    this.form.get('groups').value.forEach(g => {
      groups.push({
        id: +g,
      } as UserGroup);
    });
    const user: User = {
      email: this.form.get('email').value,
      id: userId,
      title: this.form.get('title').value,
      firstname: this.form.get('firstname').value,
      lastname: this.form.get('lastname').value,
      city: this.form.get('city').value,
      country: this.form.get('country').value,
      groups,
    };
    const coneventId: number = Number(this.form.get('coneventDBId').value);
    let userRequest: Observable<User>;
    if (userId === 0) {
      userRequest = this.iamService.userCreatePost(user);
    } else {
      userRequest = this.iamService.userIdUpdatePost(userId, user);
    }
    return userRequest.pipe(mergeMap(u => {
      return this.iamService.userIdConeventmappingPost(u.id, {
        conevent_db_id: coneventId === 0 ? null : coneventId,
      });
    }));
  }

  chooseConeventUser(user: ConeventUser): void {
    this.form.get('coneventDBId').setValue(user.id);
  }
}
