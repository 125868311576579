/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CaptchaSolution } from './captchaSolution';


export interface ForgotPasswordRequest { 
    captcha?: CaptchaSolution;
    email?: string;
}

