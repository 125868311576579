/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Forum } from './forum';


export interface Topic { 
    id?: number;
    forum?: Forum;
    name?: string;
    created_at?: Date;
    created_by?: User;
    updated_at?: Date;
    updated_by?: User;
    archived_at?: Date;
    num_messages?: number;
    last_message_at?: Date;
}

