/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileItem } from './fileItem';
import { User } from './user';
import { OnkologischePharmazieSection } from './onkologischePharmazieSection';


export interface OnkologischePharmazie { 
    id?: number;
    name?: string;
    releasedate?: Date;
    edition?: string;
    sections?: Array<OnkologischePharmazieSection>;
    file?: FileItem;
    created_at?: Date;
    created_by?: User;
    updated_at?: Date;
    updated_by?: User;
}

