import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ApiDataSource} from './api-data.source';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  template: '',
})
export abstract class ListComponent<T> implements AfterViewInit {
  loaded = false;
  dataSource: ApiDataSource<T> = new ApiDataSource<T>();
  apiRequest: (offset: number, limit: number) => Observable<HttpResponse<T[]>>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  protected constructor(route: ActivatedRoute,
                        snackBar: MatSnackBar) {
    //TODO get startpage from route
    //TODO get other parameters from route
  }

  ngAfterViewInit(): void {
    this.dataSource.loadData = this.apiRequest;
    this.dataSource.paginator = this.paginator;
    this.dataSource.fetchData();
    this.loaded = true;
  }
}
