import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { QuillModule } from 'ngx-quill';
import {FilePickerDialogComponent} from './admin/file-picker-dialog/file-picker-dialog.component';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FileGridComponent} from './admin/file-browser/file-grid/file-grid.component';
import {FileViewComponent} from './admin/file-browser/file-view/file-view.component';
import {NavigationComponent} from './admin/navigation/navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiModule, Configuration, ConfigurationParameters} from './api';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import {TokenInterceptor} from './admin/auth/token.interceptor';
import {FeedListComponent} from './admin/feed-list/feed-list.component';
import {FeedEditComponent} from './admin/feed-edit/feed-edit.component';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import {LoadingPageComponent} from './admin/loading-page/loading-page.component';
import {environment} from '../environments/environment';
import {SettingsComponent} from './admin/settings/settings.component';
import {SettingsUserListComponent} from './admin/settings-user-list/settings-user-list.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component';
import {RegisterComponent} from './register/register.component';
import {HomeComponent} from './home/home.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LegalNoticeComponent} from './legal-notice/legal-notice.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {WebpageComponent} from './webpage/webpage.component';
import {SettingsUserEditComponent} from './admin/settings-user-edit/settings-user-edit.component';
import {SettingsGeneralEditComponent} from './admin/settings-general-edit/settings-general-edit.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SettingsGroupEditComponent} from './admin/settings-group-edit/settings-group-edit.component';
import {SettingsGroupListComponent} from './admin/settings-group-list/settings-group-list.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {ForumListComponent} from './admin/forum-list/forum-list.component';
import {ForumEditComponent} from './admin/forum-edit/forum-edit.component';
import {ForumTopicDialogComponent} from './admin/forum-topic-dialog/forum-topic-dialog.component';
import {OnkoPharmListComponent} from './admin/onko-pharm-list/onko-pharm-list.component';
import {OnkoPharmEditComponent} from './admin/onko-pharm-edit/onko-pharm-edit.component';
import {AuthService} from './admin/auth/auth.service';
import {CommonModule} from '@angular/common';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    withCredentials: true,
    basePath: environment.apiUrl,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingPageComponent,
    NavigationComponent,
    FeedListComponent,
    FeedEditComponent,
    DashboardComponent,
    SettingsComponent,
    SettingsGeneralEditComponent,
    SettingsUserListComponent,
    SettingsUserEditComponent,
    SettingsGroupEditComponent,
    SettingsGroupListComponent,
    PageNotFoundComponent,
    FileGridComponent,
    FileViewComponent,
    FilePickerDialogComponent,
    ForumListComponent,
    ForumEditComponent,
    ForumTopicDialogComponent,
    OnkoPharmListComponent,
    OnkoPharmEditComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ApiModule.forRoot(apiConfigFactory),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatSnackBarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    SocialLoginModule,
    MatDatepickerModule,
    MatNativeDateModule,
    QuillModule.forRoot(),
    MatExpansionModule,
    MatGridListModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatDialogModule,
    VerifyEmailComponent,
    RegisterComponent,
    HomeComponent,
    ForgotPasswordComponent,
    LegalNoticeComponent,
    PrivacyPolicyComponent,
    WebpageComponent,
    GoogleSigninButtonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
