import {Component} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditComponent} from '../edit/edit.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Feed, Forum, ForumService, IamPolicy, IamService, Role, Topic, User} from '../../api';
import {MatDialog} from '@angular/material/dialog';
import {ForumTopicDialogComponent} from '../forum-topic-dialog/forum-topic-dialog.component';
import {FilePickerDialogComponent} from '../file-picker-dialog/file-picker-dialog.component';

@Component({
  selector: 'app-forum-edit',
  templateUrl: './forum-edit.component.html',
  styleUrls: ['./forum-edit.component.css']
})
export class ForumEditComponent extends EditComponent {
  topics: Topic[] = [];

  form = this.fb.group({
    id: [0, Validators.required],
    name: ['', Validators.required],
    description: ['', Validators.required],
    //readerGroups: [['dgop-member']],
  });

  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private forumService: ForumService,
              snackBar: MatSnackBar,
              public dialog: MatDialog) {
    super(fb, route, router, snackBar);
  }

  loadData(): Observable<any> {
    return this.route.paramMap.pipe(mergeMap(
      params => {
        const forumId = Number(params.get('forumId'));
        const sources = [];
        if (forumId !== 0) {
          sources.push(this.forumService.forumIdGetGet(forumId).pipe(map(forum => {
            this.form.get('id').setValue(forum.id);
            this.form.get('name').setValue(forum.name);
            this.form.get('description').setValue(forum.description);
          })));
          sources.push(this.forumService.forumForumIdTopicListGet(forumId).pipe(map(topics => {
            this.topics = topics;
            console.log(topics);
          })));
        } else {
          return of('');
        }
        return forkJoin(sources);
      }
    ));
  }

  storeData(): Observable<any> {
    const forum: Forum = {
      id: this.form.get('id').value,
      name: this.form.get('name').value,
      description: this.form.get('description').value,
    };

    const storeForum = forum.id === 0 ?
      this.forumService.forumCreatePost(forum)
      : this.forumService.forumIdUpdatePost(forum.id, forum);

    // return storeForum.pipe(mergeMap(ref => {
    //   this.form.get('id').setValue(ref.id);
    //
    //   const policy: IamPolicy = {
    //     bindings: [
    //       {
    //         role: 'forum.reader',
    //         members: this.form.get('readerGroups').value.map(g => 'group:' + g)
    //       }
    //     ]
    //   };
    //   return this.forumService.forumIdSetIamPolicyPost(ref.id, policy);
    // }));
    return storeForum;
  }

  onClickAddTopic(): void{
      this.handleTopicDialog({
        id: 0,
        forum: {id: this.form.get('id').value}
      });
  }

  onClickEditTopic(topic: Topic): void {
    this.handleTopicDialog(topic);
  }

  handleTopicDialog(topic: Topic): void {
    if (this.form.get('id').value === 0) {
      this.snackBar.open('Please store the Forum first.');
    } else {
      const dialogRef = this.dialog.open(ForumTopicDialogComponent, {
          data: topic,
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        console.log(topic);
        if (topic.id !== 0) {
            const isNew = this.topics.findIndex((v => v.id === topic.id)) === -1;
            console.log(isNew);
            if (isNew) {
              this.topics = this.topics.concat(topic);
            }
        }
      });
    }
  }
}
