import {Component, OnInit} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { RouterModule } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-webpage',
  templateUrl: './webpage.component.html',
  styleUrls: ['./webpage.component.css'],
  imports: [MatCardModule, RouterModule, MatButtonModule],
  standalone: true,
})
export class WebpageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
