import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Feed, NewsService} from '../../api';
import {ActivatedRoute} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {LoadingComponent} from '../loading/loading.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-congress-list',
  templateUrl: './feed-list.component.html',
  styleUrls: ['./feed-list.component.css']
})
export class FeedListComponent extends LoadingComponent {
  dataSource = new MatTableDataSource<Feed>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['title', 'type', 'info'];

  constructor(private newsService: NewsService,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  loadData(): Observable<any> {
    this.dataSource.paginator = this.paginator;
    return this.newsService.feedListGet().pipe(
      map(feeds => {
        this.dataSource.data = feeds;
      }));
  }
}
