import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {LoadingComponent} from '../../loading/loading.component';
import {FileItem, FilesService} from '../../../api';

@Component({
  selector: 'app-congress-list',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.css']
})
export class FileViewComponent extends LoadingComponent {
  currentFile: FileItem;

  constructor(private filesService: FilesService,
              private router: Router,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  handleFileDelete(fileId: number): void {
    this.filesService.filesIdDelete(fileId).subscribe(() => {
      void this.router.navigate(['admin', 'files']);
    });
  }

  loadData(): Observable<any> {
    return this.route.paramMap.pipe(mergeMap(
      params => {
        const fileId = Number(params.get('fileId'));

        return this.filesService.filesIdGet(fileId).pipe(map(file => {
          this.currentFile = file;
        }));
      }));
  }
}
