<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-slide-toggle formControlName="enableRegister">Anmeldung aktivieren</mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-slide-toggle formControlName="enableMaintenance">Wartungsmodus aktivieren (TODO)</mat-slide-toggle>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-actions>
      <button color="primary" mat-raised-button type="submit">Speichern</button>
    </mat-card-actions>
  </mat-card>
</form>
