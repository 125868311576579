/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnkologischePharmazieSection } from './onkologischePharmazieSection';
import { OnkologischePharmazie } from './onkologischePharmazie';


export interface SearchOnkoPharmResponse { 
    score?: number;
    onko_pharm?: OnkologischePharmazie;
    result_type?: string;
    page?: number;
    section?: OnkologischePharmazieSection;
}

