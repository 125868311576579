import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingComponent} from '../loading/loading.component';
import {first} from 'rxjs/operators';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-event-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export abstract class EditComponent extends LoadingComponent {
  abstract form: FormGroup;

  constructor(protected fb: FormBuilder,
              route: ActivatedRoute,
              private router: Router,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.form.disable();
      this.storeData().pipe(first()).subscribe(_ => {
        this.form.enable();
        this.printInfo('Erfolgreich gespeichert.');
        this.finalizeSubmit();
      }, err => {
        this.form.enable();
        let errorMessage = '';
        if (err.hasOwnProperty('error') && err.error.hasOwnProperty('message')) {
          errorMessage = `Error while saving event: ${err.error.message}`;
        } else {
          errorMessage = 'Error while saving event' + err;
        }
        this.printError(errorMessage, 'OK');
      });
    } else {
      // TODO maybe print errors of form
      this.printError('Form is invalid, unable to save.');
    }
  }

  finalizeSubmit(): void {
    this.router.navigate(['.'], {
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  abstract loadData(): Observable<any>;

  abstract storeData(): Observable<any>;
}
