<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Benutzer</mat-card-title>
      <mat-card-subtitle>Profil</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="email" matInput placeholder="Email">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="title" matInput placeholder="Titel">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="firstname" matInput placeholder="Vorname">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="lastname" matInput placeholder="Nachname">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="city" matInput placeholder="Stadt">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="country" matInput placeholder="Land">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Gruppen</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Gruppen</mat-label>
            <mat-select formControlName="groups" multiple>
              <mat-option *ngFor="let group of groupList" [disabled]="group.managed"
                          [value]="group.id">{{group.displayName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Status</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          Erstellt am: {{ userDetail.created_at }}<br>
          Zuletzt eingeloggt am: {{ userDetail.lastlogin_at }}<br>
          Email verifiziert:
          <mat-checkbox [checked]="userDetail.email_verified" [disabled]="true"></mat-checkbox>
        </div>
        <div class="col">
          Authentifizierungsmethoden: <br>
          Password: TODO <br>
          Google: TODO
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>ConEvent DB Mapping</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="coneventDBId" matInput placeholder="Conevent Mandant ID">
          </mat-form-field>
          <mat-accordion
            *ngIf="userDetail.conevent_db_status == 'WaitForAction' || userDetail.conevent_db_status == 'NotFound'">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Mandant auswählen
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-selection-list [multiple]="false">
                <mat-list-option (click)="chooseConeventUser(u)" *ngFor="let u of possibleConeventUsers" [value]="u">
                  {{ u.id }} - {{ u.firstname }} {{ u.lastname }} - {{ u.email }}
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>

        </div>
        <div class="col">
          Status: {{ userDetail.conevent_db_status }}
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-actions>
      <button color="primary" mat-raised-button type="submit">Submit</button>
    </mat-card-actions>
  </mat-card>
</form>
