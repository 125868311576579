import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FeedEditComponent} from './admin/feed-edit/feed-edit.component';
import {FeedListComponent} from './admin/feed-list/feed-list.component';
import {NavigationComponent} from './admin/navigation/navigation.component';
import {SettingsUserEditComponent} from './admin/settings-user-edit/settings-user-edit.component';
import {SettingsComponent} from './admin/settings/settings.component';
import {FileGridComponent} from './admin/file-browser/file-grid/file-grid.component';
import {FileViewComponent} from './admin/file-browser/file-view/file-view.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {HomeComponent} from './home/home.component';
import {LegalNoticeComponent} from './legal-notice/legal-notice.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {RegisterComponent} from './register/register.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component';
import {WebpageComponent} from './webpage/webpage.component';
import {SettingsGroupEditComponent} from './admin/settings-group-edit/settings-group-edit.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {ForumListComponent} from './admin/forum-list/forum-list.component';
import {ForumEditComponent} from './admin/forum-edit/forum-edit.component';
import {OnkoPharmListComponent} from "./admin/onko-pharm-list/onko-pharm-list.component";
import {OnkoPharmEditComponent} from "./admin/onko-pharm-edit/onko-pharm-edit.component";

const routes: Routes = [
  {
    path: 'admin',
    component: NavigationComponent,
    children: [
      {path: '', component: DashboardComponent},
      {path: 'feeds', component: FeedListComponent},
      {path: 'feed/:feedId', component: FeedEditComponent},
      {path: 'files', component: FileGridComponent},
      {path: 'files/:fileId', component: FileViewComponent},
      {path: 'forum', component: ForumListComponent},
      {path: 'forum/:forumId', component: ForumEditComponent},
      {path: 'onkopharm', component: OnkoPharmListComponent},
      {path: 'onkopharm/:onkoPharmId', component: OnkoPharmEditComponent},
      {path: 'settings', component: SettingsComponent},
      {path: 'settings/user/:userId', component: SettingsUserEditComponent},
      {path: 'settings/group/:groupId', component: SettingsGroupEditComponent}
    ]
  },
  {
    path: '',
    component: WebpageComponent,
    children: [
      {path: 'verify-email', component: VerifyEmailComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'legal-notice', component: LegalNoticeComponent},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
      {path: 'register', component: RegisterComponent},
      {path: '', component: HomeComponent},
      {path: '**', component: PageNotFoundComponent},
    ]
  },
];

@NgModule({
  declarations: [],
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {enableTracing: false})
  ]
})
export class AppRoutingModule {
}
