<app-loading-page *ngIf="!loaded"></app-loading-page>
<mat-card *ngIf="loaded">
  <mat-card-header>
    <mat-card-title>Bilder</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <input #imageUpload (change)="handleImageUpload($event)" accept="image/*" hidden="true" onclick="this.value=null"
           type="file"/>
    <button (click)="imageUpload.click()" color="primary" mat-flat-button>
      <mat-icon aria-hidden="true" inline>file_upload</mat-icon>&nbsp;
      Datei hochladen
    </button>
    <mat-progress-bar *ngIf="uploadProgress" [value]="uploadProgress" class="progress-bar"
                      mode="determinate"></mat-progress-bar>

    <div class="file-item-list">
      <mat-card (click)="handleFileClick(item.id)" *ngFor="let item of files" class="file-item">
        <img mat-card-image src="{{item.preview_url}}"/>
        <mat-card-content>
          <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{item.file_name}}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
