import {Component, OnInit} from '@angular/core';
import {MatCardModule} from "@angular/material/card";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
  imports: [MatCardModule],
  standalone: true,
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
