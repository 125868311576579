/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventTitleImage } from './eventTitleImage';


export interface Event { 
    url?: string;
    starttime?: Date;
    endtime?: Date;
    description?: string;
    titleImage?: EventTitleImage;
}

