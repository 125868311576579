<mat-card class="homepage-card">
  <mat-card-header>
    <mat-card-title routerLink="/">
      <img alt="DGOP" class="banner-logo" src="assets/dgop_banner.png"/>
      <h1>DGOP App</h1>
    </mat-card-title>
  </mat-card-header>
  <router-outlet></router-outlet>
  <mat-card-footer>
    <button mat-button routerLink="/legal-notice">Impressum</button>
    <button mat-button routerLink="/privacy-policy">Datenschutz</button>
  </mat-card-footer>
</mat-card>
