<mat-card-header><mat-card-title>Password vergessen</mat-card-title></mat-card-header>
<mat-card-content *ngIf="!tokenProvided && !successful">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="email" matInput placeholder="Email" type="email">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <input formControlName="captchaCode" matInput placeholder="Captcha Code">
        </mat-form-field>
        <img [src]="captchaImage" alt=""/>
        <button (click)="renewCaptcha()" color="accent" mat-button type="button">Neuer Captcha</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button color="primary" mat-raised-button type="submit">Password zurücksetzen</button>
      </div>
    </div>
  </form>
</mat-card-content>
<mat-card-content *ngIf="!tokenProvided && successful">
  <p>
    Sie erhalten eine Email mit weiteren Anweisungen um ihr Password zurückzusetzen.
  </p>
</mat-card-content>
<mat-card-content *ngIf="tokenProvided && !successful">
  <form (ngSubmit)="onSubmitResetForm()" [formGroup]="formReset">
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="password" matInput placeholder="Password" type="password">
          <mat-error *ngIf="formReset.get('password').hasError('invalidPassword')">
            Das Password erfüllt nicht die Anforderung*.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input formControlName="repeatPassword" matInput placeholder="Password wiederholen" type="password">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-error *ngIf="formReset.hasError('noMatch')">
          Die beiden Passwörter müssen übereinstimmen
        </mat-error>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button color="primary" mat-raised-button type="submit">Password zurücksetzen</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-hint>
          *Die Anforderung an das Password sind von mindestens ein Klein-/ und Großbuchstaben, eine Zahl und ein
          Sonderzeichen.
        </mat-hint>
      </div>
    </div>
  </form>
</mat-card-content>
<mat-card-content *ngIf="tokenProvided && successful">
  <p>
    Ihr Password wurde erfolgreich zurückgesetzt, Sie können sich nun wieder einloggen.
  </p>
</mat-card-content>
