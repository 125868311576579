import {HttpEventType} from '@angular/common/http';
import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {FileItem, FilesService} from '../../../api';
import {LoadingComponent} from '../../loading/loading.component';

@Component({
  selector: 'app-congress-list',
  templateUrl: './file-grid.component.html',
  styleUrls: ['./file-grid.component.css']
})
export class FileGridComponent extends LoadingComponent {
  files: FileItem[] = [];
  uploadProgress = 0;
  private uploadSub: Subscription;

  constructor(private filesService: FilesService,
              private router: Router,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  handleFileClick(itemId: number): void {
    void this.router.navigate(['admin', 'files', itemId]);
  }

  handleImageUpload(fileEvent: Event): void {
    const file = (fileEvent.target as HTMLInputElement).files[0];

    if (file) {
      const upload = this.filesService.filesPost(file, 'events', true)
        .pipe(finalize(() => this.completeUpload()));

      this.uploadSub = upload.subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
      });
    }

  }

  loadData(): Observable<any> {
    return this.filesService.filesGet().pipe(
      map(files => {
        this.files = files;
      }));
  }

  private completeUpload(): void {
    this.uploadProgress = 0;
    this.uploadSub = undefined;

    this.loadData().subscribe();
  }
}
