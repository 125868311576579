<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Gruppe</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="displayName" matInput placeholder="Name">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Mitglieder</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-list>
            <mat-list-item
              *ngFor="let member of members">{{member.title}} {{member.firstname}} {{member.lastname}}</mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
