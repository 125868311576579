import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSnackBarDismiss} from '@angular/material/snack-bar';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-event-edit',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export abstract class LoadingComponent implements OnInit {
  loaded = false;

  constructor(protected route: ActivatedRoute,
              protected snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.loadData().subscribe(_ => {
      this.loaded = true;
    }, err => {
      let errorMessage = '';
      if (err.hasOwnProperty('error')) {
        errorMessage = `Error: ${err.error.message}`;
      } else {
        errorMessage = err;
      }
      this.printError(errorMessage, 'OK');
    });
  }

  printError(message: string, action?: string): Observable<MatSnackBarDismiss> {
    const snackBar = this.snackBar.open(message, action, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      }
    );
    return snackBar.afterDismissed();
  }

  printInfo(message: string, action?: string): Observable<MatSnackBarDismiss> {
    console.info(message);
    const snackBar = this.snackBar.open(message, action, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      }
    );
    return snackBar.afterDismissed();
  }

  abstract loadData(): Observable<any>;
}
