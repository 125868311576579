import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators} from '@angular/forms';
import {ForgotPasswordRequest, IamService, PasswordResetRequest} from '../api';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {of} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  imports: [MatCardModule, CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule],
  standalone: true,
})
export class ForgotPasswordComponent implements OnInit {
  public captchaImage: SafeResourceUrl;
  public tokenProvided = false;
  public successful = false;

  form = this.fb.group({
    captchaId: ['', Validators.required],
    captchaTime: [0, Validators.required],
    captchaCode: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  formReset = this.fb.group({
    token: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50), this.passwordValidator]],
    repeatPassword: ['', Validators.required]
  }, {validator: this.matchPassword});

  constructor(private fb: FormBuilder,
              private iamService: IamService,
              private snackBar: MatSnackBar,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(pm => {
      if (pm.has('token')) {
        this.tokenProvided = true;
        this.formReset.get('token').setValue(pm.get('token'));
      } else {
        this.renewCaptcha();
      }
      return of('');
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const forgotRequest: ForgotPasswordRequest = {
        captcha: {
          id: this.form.get('captchaId').value,
          time: this.form.get('captchaTime').value,
          value: this.form.get('captchaCode').value
        },
        email: this.form.get('email').value,
      };
      this.iamService.userForgotPasswordRequestPost(forgotRequest).subscribe(resp => {
        this.successful = true;
      }, err => {
        this.snackBar.open(err.error.message);
      });
    }
  }

  onSubmitResetForm(): void {
    if (this.formReset.valid) {
      this.formReset.disable();
      const resetRequest: PasswordResetRequest = {
        pw_reset_code: this.formReset.get('token').value,
        password: this.formReset.get('password').value
      };
      this.iamService.userForgotPasswordResetPost(resetRequest).subscribe(resp => {
        this.successful = true;
      }, err => {
        this.formReset.enable();
      });
    }
    console.log(this.formReset);
  }

  renewCaptcha(): void {
    this.iamService.captchaGet().subscribe(res => {
      this.captchaImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64, ' + res.image);
      this.form.get('captchaId').setValue(res.id);
      this.form.get('captchaTime').setValue(res.time);
    });
  }

  matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password').value;
    const repeatPassword = control.get('repeatPassword').value;
    if (password !== repeatPassword) {
      return {noMatch: true};
    }

    return null;
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const pw = control.value;
    if (pw.search(/[a-z]/) < 0 || pw.search(/[A-Z]/) < 0 || pw.search(/[.,-_#'+*<>!"§$%&()=?]/)) {
      return {invalidPassword: true};
    }
    return null;
  }
}
