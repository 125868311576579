<mat-tab-group>
  <mat-tab label="Allgemein">
    <app-settings-general-edit></app-settings-general-edit>
  </mat-tab>
  <mat-tab label="Benutzer">
    <app-settings-user-list></app-settings-user-list>
  </mat-tab>
  <mat-tab label="Gruppen">
    <app-settings-group-list></app-settings-group-list>
  </mat-tab>
</mat-tab-group>
