<app-loading-page *ngIf="!loaded"></app-loading-page>
<mat-card *ngIf="loaded">
  <mat-card-content>
    <a [routerLink]="['./user', 0]" mat-list-item>
      <button mat-raised-button>Create</button>
    </a>
    <table [dataSource]="dataSource" aria-label="Elements" class="full-width-table" mat-table>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Title</th>
        <td *matCellDef="let row" mat-cell>{{row.title}} {{row.firstname}} {{row.lastname}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell>Email</th>
        <td *matCellDef="let row" mat-cell>{{row.email}}</td>
      </ng-container>

      <ng-container matColumnDef="dgop">
        <th *matHeaderCellDef mat-header-cell>Gruppen</th>
        <td *matCellDef="let row" mat-cell>
          <span *ngFor="let group of row.groups">
            {{group.displayName}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="info" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <a [routerLink]="['./user', element.id]">
            <mat-icon>more_vert</mat-icon>
          </a>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource?.numItem"
                   [pageIndex]="0"
                   [pageSizeOptions]="[25, 50, 100, 250]"
                   [pageSize]="50">
    </mat-paginator>
  </mat-card-content>
</mat-card>
