import {Component} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditComponent} from '../edit/edit.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {
  Feed, FileItem,
  Forum,
  ForumService,
  IamPolicy,
  IamService,
  OnkologischePharmazie,
  OnkoPharmService,
  Role,
  Topic,
  User
} from '../../api';
import {MatDialog} from '@angular/material/dialog';
import {FilePickerDialogComponent} from '../file-picker-dialog/file-picker-dialog.component';

@Component({
  selector: 'app-forum-edit',
  templateUrl: './onko-pharm-edit.component.html',
  styleUrls: ['./onko-pharm-edit.component.css']
})
export class OnkoPharmEditComponent extends EditComponent {
  topics: Topic[] = [];

  form = this.fb.group({
    id: [0, Validators.required],
    name: ['', Validators.required],
    releaseDate: ['', Validators.required],
    fileId: ['', Validators.required],
    readerGroups: [['dgop-member']],
  });

  selectedFile?: FileItem;


  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private service: OnkoPharmService,
              snackBar: MatSnackBar,
              public dialog: MatDialog) {
    super(fb, route, router, snackBar);
  }

  loadData(): Observable<any> {
    return this.route.paramMap.pipe(mergeMap(
      params => {
        const onkoPharmId = Number(params.get('onkoPharmId'));
        if (onkoPharmId !== 0) {
          const sources = [
            this.service.onkoIdGetGet(onkoPharmId).pipe(map(onkoPharm => {
              this.form.get('id').setValue(onkoPharm.id);
              this.form.get('name').setValue(onkoPharm.name);
            })),
            this.service.onkoIdGetIamPolicyGet(onkoPharmId).pipe(map(policy => {
              const readerGroups = policy.bindings.flatMap(b => {
                if (b.role === 'onkopharm.reader') {
                  return b.members.filter(m => m.startsWith('group:')).map(m => m.slice('group:'.length));
                } else {
                  return [];
                }
              });
              this.form.get('readerGroups').setValue(readerGroups);
            }))
          ];
          return forkJoin(sources);
        } else {
          return of('');
        }
      }
    ));
  }

  storeData(): Observable<any> {
    const onkologischePharmazie: OnkologischePharmazie = {
      id: this.form.get('id').value,
      name: this.form.get('name').value,
    };

    const storeOnkoPharm = onkologischePharmazie.id === 0 ?
      this.service.onkoCreatePost(onkologischePharmazie)
      : this.service.onkoIdUpdatePost(onkologischePharmazie.id, onkologischePharmazie);

    return storeOnkoPharm.pipe(mergeMap(ref => {
      this.form.get('id').setValue(ref.id);

      const policy: IamPolicy = {
        bindings: [
          {
            role: 'onkopharm.reader',
            members: this.form.get('readerGroups').value.map(g => 'group:' + g)
          }
        ]
      };
      return this.service.onkoIdSetIamPolicyPost(ref.id, policy);
    }));
  }

  openFileDialog(): void {
    const dialogRef = this.dialog.open(FilePickerDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        console.log(`Dialog result: ${JSON.stringify(result)}`);
        this.selectedFile = result;
        this.form.get('fileId').setValue(result.id);
      }
    });
  }
}
