<app-loading-page *ngIf="!loaded"></app-loading-page>
<mat-card *ngIf="loaded">
  <mat-card-header>
    <mat-card-title>Datei: {{currentFile.file_name}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div style="margin: 16px;">
      <div>
        <img class="image" src="{{currentFile.url}}"/>
      </div>
      <br/>
      <br/>
      <mat-divider></mat-divider>
      <br/>
      <br/>
      <div>
        <div>
          Link: <a href="{{currentFile.url}}" rel="noopener noreferrer" target="_blank">{{currentFile.url}}</a>
        </div>
        <div>
          Hochgeladen
          von {{currentFile.created_by.title}} {{currentFile.created_by.firstname}} {{currentFile.created_by.lastname}}
          am {{currentFile.created_at | date: 'dd.MM.yyyy HH:mm:ss'}}
          <br/>
          <br/>
        </div>
        <button (click)=handleFileDelete(currentFile.id) color="warn" mat-raised-button type="button">Löschen</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
