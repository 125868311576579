<h2 mat-dialog-title>Datei auswählen</h2>
<mat-dialog-content>
  <input #imageUpload (change)="handleImageUpload($event)" accept="image/*" hidden="true" onclick="this.value=null"
         type="file"/>
  <button (click)="imageUpload.click()" color="primary" mat-flat-button>
    <mat-icon aria-hidden="true" inline>file_upload</mat-icon>&nbsp;
    Datei hochladen
  </button>
  <mat-progress-bar *ngIf="uploadProgress" [value]="uploadProgress" class="progress-bar"
                    mode="determinate"></mat-progress-bar>

  <div class="file-item-list">
    <mat-card *ngFor="let item of files" [mat-dialog-close]="item" class="file-item">
      <img mat-card-image src="{{item.preview_url}}"/>
      <mat-card-content>
        <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{item.file_name}}</div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
