import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Feed, ForumService, IamService} from '../../api';
import {ListComponent} from '../list/list.component';

@Component({
  selector: 'app-forum-list',
  templateUrl: './forum-list.component.html',
  styleUrls: ['./forum-list.component.css']
})
export class ForumListComponent extends ListComponent<Feed> implements AfterViewInit {
  displayedColumns = ['name', 'topics', 'posts', 'last_post', 'info'];

  constructor(private forumService: ForumService,
              route: ActivatedRoute,
              snackBar: MatSnackBar) {
    super(route, snackBar);
  }

  apiRequest = (offset, limit) => this.forumService.forumListGet(offset, limit, null, 'response');
}

