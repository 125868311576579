import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators} from '@angular/forms';
import {IamService, SettingsService, User, UserRegisterRequest} from '../api';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  imports: [MatCardModule, MatProgressSpinnerModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, NgIf],
  standalone: true,
})
export class RegisterComponent implements OnInit {
  public isActive: boolean = null;
  public finalUser: User;
  public captchaImage: SafeResourceUrl;
  form = this.fb.group({
    captchaId: ['', Validators.required],
    captchaTime: [0, Validators.required],
    captchaCode: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50), this.passwordValidator]],
    repeatPassword: ['', Validators.required],
    title: [''],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
  }, {validator: this.matchPassword});

  constructor(private fb: FormBuilder,
              private iamService: IamService,
              private settingsService: SettingsService,
              private snackBar: MatSnackBar,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.renewCaptcha();
    this.settingsService.settingGetKeyGet('register_allowed').subscribe(s => {
      this.isActive = s.value;
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const registerRequest: UserRegisterRequest = {
        captcha: {
          id: this.form.get('captchaId').value,
          time: this.form.get('captchaTime').value,
          value: this.form.get('captchaCode').value
        },
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        title: this.form.get('title').value,
        firstname: this.form.get('firstname').value,
        lastname: this.form.get('lastname').value
      };
      this.iamService.userRegisterPost(registerRequest).subscribe(resp => {
        this.finalUser = resp;
      }, err => {
        console.log(err);
        this.snackBar.open(err.error.message);
        this.renewCaptcha();
      });
    } else {
      console.log(this.form.errors);
      console.log(this.form);
    }
  }

  renewCaptcha(): void {
    this.iamService.captchaGet().subscribe(res => {
      this.captchaImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64, ' + res.image);
      this.form.get('captchaId').setValue(res.id);
      this.form.get('captchaTime').setValue(res.time);
    });
  }

  matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password').value;
    const repeatPassword = control.get('repeatPassword').value;
    if (password !== repeatPassword) {
      return {noMatch: true};
    }

    return null;
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const pw = control.value;
    if (pw.search(/[a-z]/) < 0 || pw.search(/[A-Z]/) < 0 || pw.search(/[.,-_#'+*<>!"§$%&()=?]/)) {
      return {invalidPassword: true};
    }
    return null;
  }
}
