export * from './contact.service';
import { ContactService } from './contact.service';
export * from './cron.service';
import { CronService } from './cron.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './forum.service';
import { ForumService } from './forum.service';
export * from './iam.service';
import { IamService } from './iam.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './onkoPharm.service';
import { OnkoPharmService } from './onkoPharm.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export const APIS = [ContactService, CronService, FilesService, ForumService, IamService, NewsService, OnkoPharmService, SettingsService];
