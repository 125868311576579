import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private idToken: string = null;
  private lastCall: number = null;

  constructor(public auth: AuthService) {
    this.auth.idToken.subscribe((idToken) => {
      this.idToken = idToken;
      this.lastCall = Date.now();
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.idToken != null) {
      if (Date.now() - this.lastCall > 1000 * 60 * 30) {
        this.auth.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(r => {
        });
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.idToken}`
        }
      });
    }
    return next.handle(request);
  }
}
