import {formatDate} from '@angular/common';
import {Component, NgZone} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Event, Feed, FileItem, FilesService, IamPolicy, IamService, News, Newsletter, NewsService, Survey} from '../../api';
import {EditComponent} from '../edit/edit.component';
import {FilePickerDialogComponent} from '../file-picker-dialog/file-picker-dialog.component';

@Component({
  selector: 'app-referent-edit',
  templateUrl: './feed-edit.component.html',
  styleUrls: ['./feed-edit.component.css']
})
export class FeedEditComponent extends EditComponent {
  public editorModules = {
    toolbar: {
      container: [
        [ { header: [] } ],
        [ 'bold', 'italic', 'strike', 'underline' ],
        [ { color: [] } ],
        [ { list: 'ordered' }, { list: 'bullet' } ],
        [ 'link', 'image' ]
      ],
      handlers: {
        image: this.imageHandler.bind(this)
      }
    }
  };

  usergroups = [];

  form = this.fb.group({
    id: [0, Validators.required],
    type: ['', Validators.required],
    title: ['', Validators.required],
    releasedate: [formatDate(new Date(), 'yyyy-MM-dd', 'en-US'), Validators.required],
    releasetime: [formatDate(new Date(), 'HH:mm', 'en-US'), Validators.required],
    readerGroups: [['dgop-member']],
    payloadNews: this.fb.group({
      text: [''],
    }),
    payloadNewsletter: this.fb.group({
      url: [''],
    }),
    payloadEvent: this.fb.group({
      url: [''],
      multiday: [false],
      startdate: [''],
      starttime: [''],
      enddate: [''],
      endtime: [''],
      description: [''],
      titleImageId: ['']
    }),
    payloadSurvey: this.fb.group({
      url: [''],
    })
  });

  feedTypes = [
    {key: 'event', value: 'Event'},
    {key: 'news', value: 'News'},
    {key: 'newsletter', value: 'Newsletter'},
    {key: 'survey', value: 'Umfrage'},
  ];

  selectedImage?: FileItem;
  quill?: any;

  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private newsService: NewsService,
              private filesService: FilesService,
              private iamService: IamService,
              snackBar: MatSnackBar,
              public dialog: MatDialog,
              private zone: NgZone) {
    super(fb, route, router, snackBar);
  }

  loadData(): Observable<any> {
    return this.route.paramMap.pipe(mergeMap(
      params => {
        const feedId = Number(params.get('feedId'));
        const sources = [this.iamService.groupListGet(0, 20, true).pipe(map(groups => {
          this.usergroups = groups;
        }))];
        if (feedId !== 0) {
          sources.push(this.newsService.feedIdGetGet(feedId).pipe(mergeMap(feed => {
            this.initializeFormData(feed);

            if (feed.payload_event && feed.payload_event?.titleImage) {
              return this.filesService.filesIdGet(feed.payload_event?.titleImage?.id);
            }
            return of(null);
          })).pipe(map(fileInfo => {
              this.selectedImage = fileInfo;
            }))
          );

          sources.push(this.newsService.feedIdGetIamPolicyGet(feedId).pipe(map(policy => {
            const readerGroups = policy.bindings.flatMap(b => {
              if (b.role === 'feed.reader') {
                return b.members.filter(m => m.startsWith('group:')).map(m => m.slice('group:'.length));
              } else {
                return [];
              }
            });
            this.form.get('readerGroups').setValue(readerGroups);
          })));
        }
        return forkJoin(sources);
      }
    ));
  }

  storeData(): Observable<any> {
    const releasedate = new Date(this.form.get('releasedate').value
      + 'T' + this.form.get('releasetime').value + 'Z');
    let payloadNews: News = null;
    let payloadNewsletter: Newsletter = null;
    let payloadEvent: Event = null;
    let payloadSurvey: Survey = null;
    switch (this.form.get('type').value) {
      case 'news':
        payloadNews = {
          text: this.form.get('payloadNews').get('text').value
        };
        break;
      case 'newsletter':
        payloadNewsletter = {
          url: this.form.get('payloadNewsletter').get('url').value
        };
        break;
      case 'event':
        console.log(this.form.get('payloadEvent').get('startdate').value);
        console.log(this.form.get('payloadEvent').get('starttime').value);
        let starttime: Date;
        let endtime: Date;
        if (this.form.get('payloadEvent').get('multiday').value) {
          starttime = new Date(this.form.get('payloadEvent').get('startdate').value
            + 'T00:00:00Z');
          endtime = new Date(this.form.get('payloadEvent').get('enddate').value
            + 'T23:59:59Z');
        } else {
          starttime = new Date(this.form.get('payloadEvent').get('startdate').value
            + 'T' + this.form.get('payloadEvent').get('starttime').value + 'Z');
          endtime = new Date(this.form.get('payloadEvent').get('startdate').value
            + 'T' + this.form.get('payloadEvent').get('endtime').value + 'Z');
        }
        payloadEvent = {
          url: this.form.get('payloadEvent').get('url').value,
          description: this.form.get('payloadEvent').get('description').value,
          starttime,
          endtime,
          titleImage: {
            id: +this.form.get('payloadEvent').get('titleImageId').value,
            url: null
          }
        };

        break;
      case 'survey':
        payloadSurvey = {
          url: this.form.get('payloadSurvey').get('url').value
        };
        break;
    }

    const feed: Feed = {
      id: this.form.get('id').value,
      title: this.form.get('title').value,
      type: this.form.get('type').value,
      releasedate,
      payload_event: payloadEvent,
      payload_news: payloadNews,
      payload_newsletter: payloadNewsletter,
      payload_survey: payloadSurvey
    };

    const storeFeed = feed.id === 0 ?
      this.newsService.feedCreatePost(feed)
      : this.newsService.feedIdUpdatePost(feed.id, feed);

    return storeFeed.pipe(mergeMap(ref => {
      this.form.get('id').setValue(ref.id);
      console.log(this.form.get('readerGroups').value);

      const policy: IamPolicy = {
        bindings: [
          {
            role: 'feed.reader',
            members: this.form.get('readerGroups').value.map(g => 'group:' + g)
          }
          ]
      };
      return this.newsService.feedIdSetIamPolicyPost(ref.id, policy);
    }));
  }

  currentNavigationCommands(): any[] {
    return ['admin', 'feed', this.form.get('id').value];
  }

  openImageDialog(): void {
    const dialogRef = this.dialog.open(FilePickerDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        console.log(`Dialog result: ${JSON.stringify(result)}`);
        this.selectedImage = result;
        this.form.get('payloadEvent').get('titleImageId').setValue(result.id);
      }
    });
  }

  removeTitleImage(): void {
    this.selectedImage = null;
    this.form.get('payloadEvent').get('titleImageId').setValue(null);
  }

  public onEditorReady(editor: any): void {
    this.quill = editor;
  }

  public imageHandler(): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(FilePickerDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.id) {
          const range = this.quill.getSelection();
          this.quill.insertEmbed(range.index, 'image', result.url, 'user');
        }
      });
    });
  }

  private initializeFormData(feed: Feed): void {
    this.form.get('id').setValue(feed.id);
    this.form.get('title').setValue(feed.title);
    this.form.get('type').setValue(feed.type);
    this.form.get('releasedate').setValue(formatDate(feed.releasedate, 'yyyy-MM-dd', 'en-US'));
    this.form.get('releasetime').setValue(formatDate(feed.releasedate, 'HH:mm', 'en-US'));

    switch (feed.type) {
      case 'news':
        this.form.get('payloadNews').get('text').setValue(feed.payload_news.text);
        break;
      case 'newsletter':
        this.form.get('payloadNewsletter').get('url').setValue(feed.payload_newsletter.url);
        break;
      case 'event':
        this.form.get('payloadEvent').get('url').setValue(feed.payload_event.url);
        this.form.get('payloadEvent').get('description').setValue(feed.payload_event.description);
        const startdate = formatDate(feed.payload_event.starttime, 'yyyy-MM-dd', 'en-US');
        const enddate = formatDate(feed.payload_event.endtime, 'yyyy-MM-dd', 'en-US');
        const multiday = startdate !== enddate;
        const starttime = formatDate(feed.payload_event.starttime, 'HH:mm', 'en-US');
        const endtime = formatDate(feed.payload_event.endtime, 'HH:mm', 'en-US');
        this.form.get('payloadEvent').get('multiday').setValue(multiday);
        this.form.get('payloadEvent').get('startdate').setValue(startdate);
        this.form.get('payloadEvent').get('starttime').setValue(starttime);
        this.form.get('payloadEvent').get('enddate').setValue(enddate);
        this.form.get('payloadEvent').get('endtime').setValue(endtime);
        this.form.get('payloadEvent').get('titleImageId').setValue(feed.payload_event?.titleImage?.id.toString());
        break;
      case 'survey':
        this.form.get('payloadSurvey').get('url').setValue(feed.payload_survey.url);
        break;
    }
  }
}
