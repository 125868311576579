<app-loading-page *ngIf="!loaded"></app-loading-page>
<form (ngSubmit)="onSubmit()" *ngIf="loaded" [formGroup]="form" novalidate>
  <mat-card class="onko-pharm-form">
    <mat-card-header>
      <mat-card-title>Onkologische Pharmazie</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="name" matInput placeholder="Name">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="releasedate" matInput placeholder="Release date" type="date">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input formControlName="releasetime" matInput placeholder="Release time" type="time">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="title-image-actions">
            <mat-form-field class="full-width">
              <input disabled matInput placeholder="PDF" type="text" value="{{selectedFile?.file_name}}"/>
            </mat-form-field>
            <button (click)="openFileDialog()" *ngIf=!selectedFile color="secondary" mat-button type="button">
              Hinzufügen
            </button>
            <button (click)="openFileDialog()" *ngIf=selectedFile color="secondary" mat-button type="button">Ändern
            </button>
          </div>>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="onko-pharm-form">
    <mat-card-actions>
      <button color="primary" mat-raised-button type="submit">Speichern</button>
    </mat-card-actions>
  </mat-card>
</form>
