<mat-card-title>Datenschutzerklärung</mat-card-title>
<mat-card-content>
  <p>Anhand dieser Datenschutzerklärung möchten wir Sie darüber informieren und aufklären, wie bei uns mit Ihren
    personenbezogenen Daten gearbeitet wird und wie wir diese schützen. Für den Schutz Ihrer personenbezogenen Daten
    haben wir zahlreiche technische organisatorische Maßnahmen umgesetzt um den bestmöglichen Schutz Ihrer Daten zu
    gewährleisten. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass
    ein absoluter Schutz nicht gewährleistet werden kann. Das Nutzen unserer Website ist grundsätzlich auch ohne jede
    Angabe von personenbezogenen Daten möglich. Sofern Sie aber einen Service in Anspruch nehmen möchten, könnte jedoch
    eine Verarbeitung personenbezogener Daten erforderlich werden. Dies tritt zum Beispiel ein, wenn Sie uns eine E-Mail
    schreiben.</p>
  <p>Die Verarbeitung Ihrer personenbezogenen Daten, wie beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder
    Telefonnummer, erfolgt stets im Einklang mit der landesspezifischen Datenschutzbestimmung.</p>
  <p>Wir weisen darauf hin, dass unsere Website weitere Verlinkungen auf externe Webseiten enthält, wobei wir auf die
    Verarbeitung der Daten auf diesen fremden Webseiten keinen Einfluss haben.</p>
  <p>&nbsp;</p>
  <style>.impress-table, .impress-table td, .impress-table th {
    border: none;
    padding: 0;
    text-align: left
  }</style>
  <table class="impress-table">
    <tbody>
    <tr>
      <th colspan="2">Name und Kontaktdaten der verantwortlichen Stelle</th>
    </tr>
    <tr>
      <td>Name:</td>
      <td>DGOP e. V.</td>
    </tr>
    <tr>
      <td>Straße:</td>
      <td>Veritaskai 6</td>
    </tr>
    <tr>
      <td>Ort:</td>
      <td>21079 Hamburg</td>
    </tr>
    <tr>
      <td>Telefon:</td>
      <td>040 / 466 5000</td>
    </tr>
    <tr>
      <td>Fax:</td>
      <td>040 / 466 500 300</td>
    </tr>
    <tr>
      <td>E-Mail</td>
      <td>mitgliederservice [at] dgop.org</td>
    </tr>
    </tbody>
  </table>

  <p><strong>Zuständige Aufsichtsbehörde</strong></p>
  <p><br>
  </p>
  <table class="impress-table">
    <tbody>
    <tr>
      <th colspan="2">Zuständige Aufsichtsbehörde</th>
    </tr>
    <tr>
      <td colspan="2">Der Hamburgische Beauftragte für Datenschutz und Informationsfreiheit</td>
    </tr>
    <tr>
      <td>Straße:</td>
      <td>Klosterwall 6 Block C</td>
    </tr>
    <tr>
      <td>Ort:</td>
      <td>20095 Hamburg</td>
    </tr>
    <tr>
      <td>E-Mail:</td>
      <td><a href="mailto:mailbox@datenschutz.hamburg.de">mailbox [at] datenschutz.hamburg.de</a></td>
    </tr>
    <tr>
      <td>Website:</td>
      <td><a href="https://datenschutz-hamburg.de/">https://datenschutz-hamburg.de/</a></td>
    </tr>
    </tbody>
  </table>

  <p>Die nachstehenden Informationen zur Datenverarbeitung beziehen sich auf den Besuch unserer Website. Beim Besuch
    unserer Website werden keine Daten an Dritte weitergegeben.</p>
  <br clear="all">

  <p><strong>Begriffsbestimmungen</strong></p>
  <p>Die Datenschutzerklärung der DGOP e.V. beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien-
    und Verordnungsgeber beim Erlass der EU-Datenschutz-Grundverordnung verwendet wurden.</p>
  <p>Auszug aus Artikel 4 Datenschutzgrundverordnung (EU-DSGVO)</p>
  <ul>
    <li>„<strong>personenbezogene Daten</strong>“ alle Informationen, die sich auf eine identifizierte oder
      identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine
      natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
      Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen
      Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
      wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind;
    </li>
  </ul>

  <ul>
    <li>„<strong>Verarbeitung</strong>“ jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder
      jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die
      Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
      Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich
      oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung;
    </li>
  </ul>
  <ul>
    <li>„<strong>Einschränkung der Verarbeitung</strong>“ die Markierung gespeicherter personenbezogener Daten mit dem
      Ziel, ihre künftige Verarbeitung einzuschränken;
    </li>
  </ul>

  <ul>
    <li>„<strong>Auftragsverarbeiter</strong>“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
      Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet;
    </li>
  </ul>
  <ul>
    <li>„<strong>Empfänger</strong>“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
      denen personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt
      oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
      Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger; die
      Verarbeitung dieser Daten durch die genannten Behörden erfolgt im Einklang mit den geltenden
      Datenschutzvorschriften gemäß den Zwecken der Verarbeitung;
    </li>
  </ul>

  <ul>
    <li>„<strong>Einwilligung</strong>“ der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter
      Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen
      bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
      betreffenden personenbezogenen Daten einverstanden ist;
    </li>
  </ul>
  <ul>
    <li>„<strong>Dritter</strong>“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
      außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der
      unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen
      Daten zu verarbeiten;
    </li>
  </ul>

  <p><strong>Erfassung von allgemeinen Daten und Informationen</strong></p>
  <p>Beim Aufrufen unserer Website werden Daten von Ihnen erhoben und in sogenannten „Server-Logfiles“ gespeichert. Im
    Folgenden werden verschiedene Daten Protokolliert:</p>
  <ul>
    <li>Die besuchte Website</li>
    <li>Uhrzeit und Datum des Zugriffs unserer Website</li>
    <li>Der Browsertyp und Version</li>
    <li>Die übertragende Datenmenge</li>
    <li>Meldung, ob der Zugriff / Abruf unserer Website erfolgreich war</li>
    <li>Verwendete IP-Adresse</li>
  </ul>

  <p>Diese Daten werden lediglich zur Sicherstellung und Verbesserung des Angebotes ausgewertet und anschließend
    gelöscht. Eine andere Verwendung Ihrer Daten oder aber eine Weitergabe an Dritte findet nicht statt.</p>

  <p><strong>Google Analytics</strong></p>
  <p>Auf unserer Website nutzen wir Google Analytics, ein Webanalysedienst der Google Inc. 1600 Amphitheatre Parkway,
    Mountain View, CA 94043, USA.</p>
  <p>Google Analytics setzt sogenannte „Cookies“, Textdateien, die auf Ihrem Endgerät gespeichert werden ein. Damit wird
    Analyse der Benutzung der durch Sie besuchten Webseiten ermöglicht.</p>
  <p>Weitere Informationen über die für die Verarbeitung Verantwortlichen, können Sie aus den folgenden
    Datenschutzhinweisen entnehmen.</p>
  <p>Google Analytics:&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>
  </p>
  <p>Rechtsgrundlage der Verarbeitung ist:</p>
  <ul>
    <li>Berechtigtes Interesse nach Artikel 6 Absatz 1 Buchstabe f EU-DSGVO</li>
  </ul>

  <p>Für die Verarbeitung Ihrer Daten, die von Google Analytics durchgeführt wird, haben wir einen Vertrag zur
    Auftragsverarbeitung nach Artikel 28 EU-DSGVO mit Google Inc. geschlossen. <br clear="all">
  </p>
  <p><strong>Nutzung Ihrer Daten aus dem Kontaktformular</strong></p>
  <p>Die personenbezogenen Daten, die Sie uns im Rahmen einer Kontaktanfrage zur Verfügung stellen
    (http://www.dgop.org/kontakt.html), werden nur für die Beantwortung Ihrer Anfrage bzw. Kontaktaufnahme und für die
    damit verbundene technische Administration verwendet. Die Weitergabe an Dritte findet nicht statt. Wenn Sie über
    dieses Kontaktformular mit uns in Kontakt treten, werden die in den Eingabefeldern eingegeben Daten von uns
    verarbeitet.</p>
  <p>Folgende Daten sind als Pflichtfeldangaben umfasst:</p>
  <ul>
    <li>Name</li>
    <li>E-Mail</li>
    <li>Ihre Nachricht</li>
  </ul>

  <p>Darüber hinaus können Sie weitere Daten als freiwillige Angaben hinzufügen. Dies kann die Bearbeitung Ihrer Anfrage
    gegebenenfalls vereinfachen und beschleunigen. Hierbei sind folgende Daten potentiell betroffen:</p>
  <ul>
    <li>Telefonnummer</li>
  </ul>

  <p>Bitte beachten Sie, dass der Umfang der erhobenen personenbezogenen Daten im Rahmen des Kontaktformulars auch davon
    abhängt, welche Daten Sie selbst im Kontaktformular im insbesondere im Feld „Ihre Nachricht“ angeben.</p>
  <p>Zweck der Verarbeitung der personenbezogenen Daten im Rahmen der Pflichtangaben sowie der freiwilligen Angaben ist
    es, die Kontaktanfrage zu bearbeiten und zwecks Ihrer Anfrage mit Ihnen in Kontakt treten zu können.</p>
  <p>Rechtsgrundlage für die Verarbeitung der von Ihnen angegebenen personenbezogenen Daten im Rahmen des Kontakts ist
    Artikel 6 Absatz 1 Buchstabe b EU-DSGVO.</p>
  <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
    ist.</p>
  <p><strong>Newsletter</strong></p>
  <p>Bei der Anmeldung zum Bezug unseres Newsletters werden die von Ihnen angegebenen Daten ausschließlich für diesen
    Zweck verwendet. Abonnenten können auch über Umstände per E-Mail informiert werden, die für den Dienst oder die
    Registrierung relevant sind (Beispielsweise Änderungen des Newsletter Angebots oder technische Gegebenheiten).<br>
    Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung
    tatsächlich durch den Inhaber einer E-Mail Adresse erfolgt, setzen wir das „double-Opt-in“ Verfahren ein. Hierzu
    protokollieren wir die Bestellung des Newsletters, den Versand einer Bestätigungsmail und den Eingang der hiermit
    angeforderten Antwort. Weitere Daten werden nicht erhoben. Die Daten werden ausschließlich für den
    Newsletter-Versand verwendet und nicht an Dritte weitergegeben.<br>
    Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletter Versand können Sie
    jederzeit widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie sich
    jederzeit auch direkt auf dieser Webseite abmelden oder uns Ihren entsprechenden Wunsch über die am Ende dieses
    Dokuments angegebene Kontaktmöglichkeiten mitteilen.</p>
  <p>Rechtsgrundlage für die Verarbeitung ist:</p>
  <ul>
    <li>Berechtigtes Interesse Artikel 6 Absatz 1 Buchstabe f EU-DSGVO</li>
    <li>Einwilligung der Nutzer nach Artikel 6 Absatz 1 Buchstabe a EU-DSGVO</li>
  </ul>

  <p><strong>Cookies</strong></p>
  <p>Wir verwenden auf unserer Website Cookies, dabei handelt es sich um kleine Textdateien, welche auf Ihrem Endgerät
    gespeichert werden. Durch den Einsatz von Cookies wollen wir die Benutzerfreundlichkeit unserer Website erhöhen und
    stetig verbessern. In den Einstellungsoptionen in Ihrem Browser können Sie Cookies deaktivieren, jedoch können wir
    nicht gewährleisten, dass Sie auf alle Funktionen unserer Website weiterhin uneingeschränkten Zugriff haben.</p>
  <p>Rechtsgrundlage für die Verarbeitung ist:</p>
  <ul>
    <li>Berechtigtes Interesse nach Artikel 6 Absatz 1 Buchstabe f EU-DSGVO</li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>Kontaktaufnahme</strong></p>
  <p>Nehmen Sie Kontakt mit uns auf, so findet eine Verarbeitung verschiedener Daten statt, wie im Folgenden
    beschrieben:</p>
  <ul>
    <li><strong>Postalische Kontaktaufnahme:</strong></li>
  </ul>
  <p>Hier können insbesondere Adressdaten (z.B. Name, Vorname, Straße, Wohnort, Postleitzahl), Datum und Zeitpunkt des
    Posteinganges sowie jene Daten, die sich aus Ihrem Schreiben selbst ergeben, verarbeitet werden.</p>
  <ul>
    <li><strong>Telefonischer Kontakt</strong></li>
  </ul>
  <p>Hier werden insbesondere Ihre Telefonnummer sowie ggf. im Rahmen des Gespräches auf Nachfrage Ihr Name, Vorname,
    E-Mail-Adresse, Zeitpunkt des Anrufes, sowie Details zu Ihrem Anliegen, verarbeitet.</p>
  <ul>
    <li><strong>Kontaktaufnahme per Fax</strong></li>
  </ul>
  <p>Hier werden insbesondere Ihre Faxnummer bzw. die Absenderkennung sowie die sich aus dem Fax ergebenen Daten
    verarbeitet.</p>
  <ul>
    <li><strong>Kontaktaufnahme per E-Mail</strong></li>
  </ul>
  <p>Hier werden insbesondere Ihre E-Mail-Adresse, Zeitpunkt der E-Mail sowie jene Daten, die sich aus der E-Mail selbst
    ergeben, verarbeitet.</p>
  <p>Zweck der Verarbeitung ist die Kontaktanfrage zu bearbeiten und zur Beantwortung Ihrer Anfrage.</p>
  <p>Rechtsgrundlage für die Verarbeitung ist:</p>
  <ul>
    <li>Berechtigtes Interesse nach Artikel 6 Absatz 1 Buchstabe f EU-DSGVO</li>
  </ul>
  <br clear="all">

  <p><strong>Datensparsamkeit</strong></p>
  <p>Personenbezogene Daten speichern wir gemäß den Grundsätzen der Datenvermeidung und Datensparsamkeit nur so lange,
    wie es erforderlich ist oder vom Gesetzgeber her vorgeschrieben wird (gesetzliche Speicherfristen). Entfällt der
    Zweck der erhobenen Informationen oder endet die Speicherfrist, sperren oder löschen wir die Daten.</p>
  <p>&nbsp;</p>
  <p><strong>Betroffenenrechte</strong></p>
  <ul>
    <li><strong>Artikel 15 EU-DSGVO:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Auskunftsrecht der betroffenen Person</strong>
    </li>
  </ul>
  <p>Sie haben das Recht, Auskunft darüber zu erhalten, welche Daten wir zu Ihrer Person verarbeiten.</p>
  <ul>
    <li><strong>Artikel 16 EU-DSGVO:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recht auf Berichtigung</strong></li>
  </ul>
  <p>Sollten die betreffenden Daten nicht richtig oder unvollständig sein, so können Sie die Berichtigung unrichtiger
    oder die Vervollständigung unvollständiger Angaben verlangen.</p>
  <ul>
    <li><strong>Artikel 17 EU-DSGVO:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recht auf Löschung „Recht auf Vergessen
      werden“</strong></li>
  </ul>
  <p>Unter den Voraussetzungen des Artikels 17 EU-DSGVO können Sie die Löschung Ihrer personenbezogenen Daten verlangen.
    Ihr Anspruch auf Löschung hängt unter anderem davon ab, ob die Sie betreffenden Daten von uns zur Erfüllung einer
    gesetzlichen Aufgabe noch benötigt werden.</p>
  <ul>
    <li><strong>Artikel 18 EU-DSGVO:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recht auf Einschränkung der
      Verarbeitung</strong></li>
  </ul>
  <p>Unter den Voraussetzungen des Artikels 18 EU-DSGVO können Sie die Einschränkung der Verarbeitung der Sie
    betreffenden personenbezogenen Daten verlangen.</p>
  <ul>
    <li><strong>Artikel 21 EU-DSGVO:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Widerspruchsrecht</strong></li>
  </ul>
  <p>Aus Gründen, die sich aus Ihrer besonderen Situation ergeben, können Sie jederzeit gegen die Verarbeitung der Sie
    betreffenden Daten Widerspruch einlegen.</p>
  <ul>
    <li><strong>Artikel 7 Absatz 3 EU-DSGVO: Recht auf Widerruf der Einwilligung</strong></li>
  </ul>
  <p>Sie haben das Recht, eine erteilte Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten jederzeit zu
    widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zu
    Widerruf erfolgten Verarbeitung nicht berührt.</p>
  <ul>
    <li><strong>Artikel 20 EU-DSGVO: Recht auf Datenübertragbarkeit</strong></li>
  </ul>
  <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie einem Verantwortlichen bereitgestellt
    haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.</p>

  <p>Wenn Sie der Auffassung sind, dass wir bei der Verarbeitung Ihrer Daten datenschutzrechtliche Vorschriften nicht
    beachtet haben, können Sie sich mit einer Beschwerde an die zuständige Aufsichtsbehörde wenden.</p>
</mat-card-content>
