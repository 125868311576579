<mat-sidenav-container *ngIf="loggedIn && isAdmin()" class="sidenav-container">
  <mat-sidenav #drawer [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false"
               class="sidenav"
               fixedInViewport>
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/admin">Dashboard</a>
      <a mat-list-item routerLink="feeds">Feed</a>
      <a mat-list-item routerLink="files">Dateien</a>
      <a mat-list-item routerLink="forum">Forum</a>
      <a mat-list-item routerLink="onkopharm">Onkologische Pharmazie</a>
    </mat-nav-list>
    <hr>
    <mat-nav-list>
      <a mat-list-item routerLink="settings">Einstellungen</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        (click)="drawer.toggle()"
        aria-label="Toggle sidenav"
        mat-icon-button
        type="button">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{title}}</span>
      <span class="navi-spacer"></span>
      <span *ngIf="loggedIn">
        {{ user?.firstname }} {{ user?.lastname }}
        <button (click)="signOut()" mat-raised-button>Logout</button>
      </span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-card *ngIf="loggedIn && !isAdmin()" class="login-card">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    Sie sind nicht berechtigt diese Seite zu sehen.
  </mat-card-content>
</mat-card>
<mat-card *ngIf="!loggedIn" class="login-card">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
  </mat-card-content>
  <mat-card-actions>
    <asl-google-signin-button type='standard' size='medium'></asl-google-signin-button>
  </mat-card-actions>
</mat-card>
