import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {environment} from '../../../environments/environment';
import {IamService, User} from '../../api';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      share()
    );
  user: User;
  loggedIn: boolean;
  title: string = environment.production ? 'DGOP App Backend' : 'DGOP App Backend (DEV)';
  private socialUser: SocialUser;

  constructor(private breakpointObserver: BreakpointObserver,
              private authService: AuthService,
              private iamService: IamService) {
  }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.socialUser = user;
      this.loggedIn = (user != null);
      this.iamService.userMeGet().subscribe(u => {
        this.user = u;
      });
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  isAdmin(): boolean {
    if (this.user == null) {
      return false;
    }
    for (const group of this.user.groups) {
      if (group.name === 'admin') { // TODO check permission backend.display
        return true;
      }
    }
    return false;
  }
}
