import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditComponent} from '../edit/edit.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SettingFlag, SettingsService, UserGroup} from '../../api';

@Component({
  selector: 'app-settings-general-edit',
  templateUrl: './settings-general-edit.component.html',
  styleUrls: ['./settings-general-edit.component.css']
})
export class SettingsGeneralEditComponent extends EditComponent {
  groupList: UserGroup[] = [];

  form = this.fb.group({
    enableRegister: [false, Validators.required],
  });

  constructor(fb: FormBuilder,
              route: ActivatedRoute,
              router: Router,
              private settingsService: SettingsService,
              snackBar: MatSnackBar) {
    super(fb, route, router, snackBar);
  }

  loadData(): Observable<any> {
    return this.settingsService.settingGetKeyGet('register_allowed').pipe(map(s => {
      this.form.get('enableRegister').setValue(s.value);
    }));
  }

  storeData(): Observable<any> {
    const registerEnabledFlag: SettingFlag = {
      key: 'register_allowed',
      value: this.form.get('enableRegister').value
    };
    return this.settingsService.settingSetPost(registerEnabledFlag);
  }
}
