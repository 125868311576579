/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterOptionOptions } from './filterOptionOptions';


export interface FilterOption { 
    key?: string;
    name?: string;
    type?: FilterOption.TypeEnum;
    options?: Array<FilterOptionOptions>;
}
export namespace FilterOption {
    export type TypeEnum = 'text' | 'select' | 'multiselect';
    export const TypeEnum = {
        Text: 'text' as TypeEnum,
        Select: 'select' as TypeEnum,
        Multiselect: 'multiselect' as TypeEnum
    };
}


