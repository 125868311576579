<mat-card-content>
  <p>
    Die DGOPApp ist die offizielle App für die Mitglieder der Deutsche Gesellschaft für Onkologische Pharmazie(DGOP).
  </p>
  <p>
    Für weitere Informationen besuchen Sie <a href="https://dgop.org">dgop.org</a>.
  </p>
</mat-card-content>
<mat-card-actions>
  <a
    href='https://play.google.com/store/apps/details?id=com.conevent.dgopapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
    alt='Jetzt bei Google Play' class="store-button"
    src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'/></a>
  <a href="https://apps.apple.com/us/app/dgop-app/id1546995297?itsct=apps_box_badge&amp;itscg=30200"
     style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;"><img
    alt="Download on the App Store"
    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1617062400&h=9e42c538d8d040cf85dbc807170f22a3"
    style="border-radius: 13px; width: 250px; height: 83px;"></a>
</mat-card-actions>
