/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Newsletter } from './newsletter';
import { Survey } from './survey';
import { User } from './user';
import { News } from './news';
import { Event } from './event';


export interface Feed { 
    id?: number;
    type?: string;
    title?: string;
    responsible_user?: User;
    created_at?: Date;
    created_by?: User;
    updated_at?: Date;
    updated_by?: User;
    releasedate?: Date;
    archived?: boolean;
    enabled_notification?: boolean;
    payload_news?: News;
    payload_survey?: Survey;
    payload_event?: Event;
    payload_newsletter?: Newsletter;
}

