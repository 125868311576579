/**
 * DGOP App API
 * API of DGOP App.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ForumSubscriptionPushNotification } from './forumSubscriptionPushNotification';
import { Topic } from './topic';


export interface TopicSubscription { 
    topic?: Topic;
    created_at?: Date;
    push_notification?: ForumSubscriptionPushNotification;
}

